import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Section from '../components/Section/Section';
import SectionContentContainer from '../components/Section/SectionContentContainer';
import SectionEntry from '../components/Section/SectionEntry';
import SectionTitle from '../components/Section/SectionTitle';

const Approach = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query getApproachSection {
      contentfulApproachSectionTitle {
        title
      }
      allContentfulApproachSectionEntry(sort: { fields: positionInLayout }) {
        nodes {
          description {
            description
          }
          icon {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const { title } = data.contentfulApproachSectionTitle;
  const { nodes } = data.allContentfulApproachSectionEntry;

  return (
    <Section ref={ref}>
      <SectionTitle>
        <h3>{title}</h3>
      </SectionTitle>
      <SectionContentContainer approachSection={true} flexDirection="column">
        {nodes.map(({ description, icon }, key) => (
          <SectionEntry
            key={key}
            icon={icon ? icon.file.url : null}
            description={description.description}
          />
        ))}
      </SectionContentContainer>
    </Section>
  );
});

export default Approach;
