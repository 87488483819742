import React from 'react';
import styled from 'styled-components';
import hamburgerButton from '../../../images/hamburgerButton.svg';

const HamburgerButtonContainer = styled.button`
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  margin-right: 5%;
  width: 2.5rem;

  :focus {
    outline: none; 
  }

  > img {
    height: 1.2rem;
  }

  @media (min-width: ${props => props.theme.media_breakpoint_desktop}) {
    display: none;
  }
`;

const HamburgerButton = ({ handleSidebarToggle }) => {
  return (
    <>
      <HamburgerButtonContainer onClick={handleSidebarToggle}>
        <img src={hamburgerButton} alt="menu" />
      </HamburgerButtonContainer>
    </>
  );
};

export default HamburgerButton;
