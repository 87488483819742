import React from 'react';
import styled, { css } from 'styled-components';

const ApproachSectionMixin = css`
  > :first-child {
    width: 80%;
  }

  > div:not(:first-child) {
    display: flex;
    flex-direction: row;
    width: 80%;

    > :first-child {
      width: 30%;
    }

    > :last-child {
      width: 70%;
    }
  }

  > div:not(:first-child) {
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  width: 90vw;

  > div:not(:last-child) {
    margin-bottom: 3rem;
  }

  @media (min-width: ${props => props.theme.media_breakpoint_tablet}) {
    flex-direction: ${props =>
      props.flexDirection === 'column' ? 'column' : 'row'};

    > div {
      min-height: ${props => (props.icons ? '270px' : '100px')};
      width: 35%;
    }

    > div:not(:last-child) {
      margin-bottom: 0;
    }

    ${props => (props.approachSection ? ApproachSectionMixin : null)};
  }
`;

const SectionContentContainer = ({
  approachSection = false,
  children,
  flexDirection,
  hasIcons = false,
}) => {
  return (
    <Container
      approachSection={approachSection}
      icons={hasIcons}
      flexDirection={flexDirection}
    >
      {children}
    </Container>
  );
};

export default SectionContentContainer;
