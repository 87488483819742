import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.background_color_secondary};
  height: 20vh;
  width: 100vw;
`;

const Footer = () => {
  return <FooterContainer></FooterContainer>;
};

export default Footer;
