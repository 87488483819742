import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/NavBar';
import Sidebar from '../Sidebar/Sidebar';

const Layout = props => {
  return (
    <>
      <Sidebar {...props} />
      <Navbar {...props} />
      <main>{props.children}</main>
      <Footer />
    </>
  );
};

export default Layout;
