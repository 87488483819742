// @flow

import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import About from '../../sections/About';
import AboutInfo from '../../sections/AboutInfo';
import Affiliations from '../../sections/Affiliations';
import Approach from '../../sections/Approach';
import Clients from '../../sections/Clients';
import Contact from '../../sections/Contact';
import debounce from '../../utils/debounce';
import GlobalStyle from '../../styles/globalStyles';
import HeroBanner from '../HeroBanner/HeroBanner';
import Layout from '../Layout/Layout';
import Leadership from '../../sections/Leadership';
import ScrollUpButton from '../ScrollUpButton/ScrollUpButton';
import SEO from '../seo';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100vw;
`;

const Wrapper = () => {
  const ref = useRef(null);
  const aboutRef = useRef(null);
  const clientsRef = useRef(null);
  const approachRef = useRef(null);
  const affiliationsRef = useRef(null);
  const contactRef = useRef(null);

  // Refs for scroll points
  const initSectionsRefs = {
    About: aboutRef,
    Clients: clientsRef,
    Approach: approachRef,
    Affiliations: affiliationsRef,
    Contact: contactRef,
  };

  // Handle scroll logic
  const getParentRef = () => {
    return ref;
  };

  const handleScroll = (e, ref) => {
    e.preventDefault();

    let yOffset = 0;
    if (windowWidth >= 1024) {
      yOffset = -100;
    } else if (windowWidth >= 768) {
      yOffset = -125;
    } else {
      yOffset = -80;
    }

    const y =
      ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  // Handle sidebar toggle logic
  const [isSidebarToggled, setSidebarToggled] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarToggled(!isSidebarToggled);
  };

  const handleSidebarNavigation = (e, ref) => {
    handleScroll(e, ref);
    setSidebarToggled(!isSidebarToggled);
  };

  // Handle window resizing
  const [windowWidth, setWindowWidth] = useState(0);

  const handleResize = e => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 200));
    return function() {
      window.removeEventListener('resize', debounce(handleResize, 200));
    };
  }, [windowWidth]);

  return (
    <StyledWrapper ref={ref}>
      <ScrollUpButton getParentRef={getParentRef} />
      <Layout
        getParentRef={getParentRef}
        showSideBar={isSidebarToggled}
        handleSidebarToggle={handleSidebarToggle}
        handleSidebarNavigation={handleSidebarNavigation}
        handleScroll={handleScroll}
        sectionsRefs={initSectionsRefs}
      >
        <GlobalStyle />
        <SEO title="Anthony Electric" />
        <HeroBanner />
        <About ref={aboutRef} />
        <AboutInfo />
        <Clients ref={clientsRef} />
        <Approach ref={approachRef} />
        <Affiliations ref={affiliationsRef} />
        <Leadership ref={contactRef} />
        <Contact />
      </Layout>
    </StyledWrapper>
  );
};

export default Wrapper;
