import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CompanyCard from '../components/CompanyCard/CompanyCard';
import Section from '../components/Section/Section';
import SectionContentContainer from '../components/Section/SectionContentContainer';
import SectionTitle from '../components/Section/SectionTitle';

const Affiliations = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query getAffiliationsSection {
      contentfulAffiliationsSectionTitle {
        title
      }
      allContentfulAffiliationsSectionEntry {
        nodes {
          name
          websiteUrl
          logo {
            fluid {
              src
            }
          }
        }
      }
    }
  `);

  const { title } = data.contentfulAffiliationsSectionTitle;
  const { nodes } = data.allContentfulAffiliationsSectionEntry;

  return (
    <Section ref={ref} backgroundColor="grey">
      <SectionTitle>
        <h2>{title}</h2>
      </SectionTitle>
      <SectionContentContainer>
        {nodes.map(({ logo, name, websiteUrl }, key) => (
          <CompanyCard
            key={key}
            img={logo.fluid.src}
            name={name}
            url={websiteUrl}
          />
        ))}
      </SectionContentContainer>
    </Section>
  );
});

export default Affiliations;
