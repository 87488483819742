const theme = {
  background_color_primary: '#fff',
  background_color_secondary: '#efefef',
  button_color: '#4a97c2',
  color_black: '#222',
  color_grey: '#555',
  color_lightGrey: '#666',
  header_box_shadow: '0px 2px 5px rgba(0,0,0,0.1)',
  media_breakpoint_tablet: '768px',
  media_breakpoint_desktop: '1024px',
};

export default theme;
