import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  align-items: center;
  background-color: ${props =>
    props.backgroundColor === 'grey'
      ? props.theme.background_color_secondary
      : props.theme.background_color_primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 10vh;
  width: 100vw;
`; 

const Divider = styled.div`
  background-color: ${props => props.theme.background_color_secondary};
  height: 1px;
  width: 90%;
`;

const Section = React.forwardRef(
  ({ divider, children, backgroundColor }, ref) => {
    return (
      <StyledSection ref={ref} backgroundColor={backgroundColor}>
        {divider ? <Divider /> : null}
        {children}
      </StyledSection>
    );
  },
);

export default Section;
