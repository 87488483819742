import styled from 'styled-components';

const NavContainer = styled.nav`
  align-items: center;
  background-color: ${props => props.theme.background_color_primary};
  box-shadow: ${props => props.theme.header_box_shadow};
  display: flex;
  height: 13vh;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
`;

export default NavContainer;
