import styled from 'styled-components';

const LinksContainer = styled.ul`
  align-items: center;
  display: none;
  height: 80%;
  justify-content: center;
  margin-right: 5%;
  padding-left: 9%;
  width: 77%;

  li:not(:last-child) {
    margin-right: 2rem;
  }

  @media (min-width: ${props => props.theme.media_breakpoint_desktop}) {
    display: flex;
  }
`;

export default LinksContainer;
