// @flow
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  linkTitle: String,
};

const StyledLinkItem = styled.li`
  > button {
    background-color: #fff;
    border: none;
    color: ${props => props.theme.color_grey};
    font-size: 0.85rem;
    font-weight: 400;
    padding: 0;
    text-decoration: none;

    :focus {
      outline: 0;
    }

    :hover {
      color: #3b86b0;
    }
  }
`;

const LinkItem = ({ linkTitle, handleClick }: Props): React.Node => {
  return (
    <StyledLinkItem>
      <button onClick={handleClick}>{linkTitle}</button>
    </StyledLinkItem>
  );
};

export default LinkItem;
