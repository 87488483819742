import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '../components/Section/Section';
import SectionContentContainer from '../components/Section/SectionContentContainer';
import SectionEntry from '../components/Section/SectionEntry';
import SectionTitle from '../components/Section/SectionTitle';

const AboutInfo = () => {
  const data = useStaticQuery(graphql`
    query getAboutInfoSection {
      contentfulAboutInfoSectionTitle {
        title
      }
      allContentfulAboutInfoSectionEntry {
        nodes {
          title
          description {
            description
          }
          icon {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const { title } = data.contentfulAboutInfoSectionTitle;
  const { nodes } = data.allContentfulAboutInfoSectionEntry;

  return (
    <Section divider>
      <SectionTitle>
        <h3>{title}</h3>
      </SectionTitle>
      <SectionContentContainer>
        {/* Grab data from Contentful */}
        {nodes.map(({ description, icon, title }, key) => (
          <SectionEntry
            key={key}
            icon={icon.file.url}
            title={title}
            description={description.description}
          />
        ))}
      </SectionContentContainer>
    </Section>
  );
};

export default AboutInfo;
