import styled from 'styled-components';

const Logo = styled.div`
  align-items: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 700;
  height: 80%;
  justify-content: center;
  margin-left: 5%;
  pointer-events: none;

  @media (min-width: ${props => props.theme.media_breakpoint_tablet}) {
    width: 30%;
  }
`;

export default Logo;
