import styled from 'styled-components';

const SectionTitle = styled.div`
  margin: 3rem 0 2rem;
  text-align: center;

  h2,
  h3,
  h4 {
    font-weight: 600;
    line-height: 1.5;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.313rem;
  }
`;

export default SectionTitle;
