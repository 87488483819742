// @flow
import * as React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  width: 100%;

  .companyImage {
    align-items: center;
    display: flex;
    height: 90%;

    > img {
      max-height: 200px;
      max-width: 95%;
    }
  }

  .companyName {
    padding-bottom: 40px;
    text-align: center;

    > a {
      color: ${props => props.theme.color_black};
      font-weight: 600;
      line-height: 1.5;

      :hover {
        color: #3b86b0;
      }
    }
  }

  @media (min-width: ${props => props.theme.media_breakpoint_tablet}) {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const CompanyCard = ({ img, name, url }) => {
  return (
    <CardContainer>
      <div className="companyImage">
        <img src={img} alt={name} />
      </div>
      <div className="companyName">
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </div>
    </CardContainer>
  );
};

export default CompanyCard;
