import React from 'react';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  background-color: #262626;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  left: ${props => (props.show === true ? '0' : '-70vw')};
  padding-top: 40px;
  position: fixed;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 70vw;

  @media (min-width: ${props => props.theme.media_breakpoint_tablet}) {
    padding-top: 70px;
  }

  @media (min-width: ${props => props.theme.media_breakpoint_desktop}) {
    display: none;
  }
`;

const SidebarNavItem = styled.div`
  align-items: center;
  background-color: ${props =>
    props.header ? 'rgba(0, 0, 0, 0.1)' : '#262626'};
  border-bottom: 1px solid rgba(255, 255, 255, 0.035);
  color: #707070;
  display: flex;
  font-size: 0.85rem;
  height: 50px;
  padding: 1.2rem;

  @media (min-width: ${props => props.theme.media_breakpoint_tablet}) {
    padding: 2rem;
  }
`;

const Sidebar = ({
  getParentRef,
  showSideBar,
  handleSidebarToggle,
  handleSidebarNavigation,
  handleScroll,
  sectionsRefs,
}) => {
  const wrapperRef = getParentRef();
  return (
    <SidebarContainer show={showSideBar}>
      <SidebarNavItem onClick={handleSidebarToggle} header>
        Nav
      </SidebarNavItem>
      <SidebarNavItem onClick={e => handleSidebarNavigation(e, wrapperRef)}>
        Home
      </SidebarNavItem>
      {Object.entries(sectionsRefs).map((section, key) => (
        <SidebarNavItem
          key={key}
          onClick={e => handleSidebarNavigation(e, section[1])}
        >
          {section[0]}
        </SidebarNavItem> 
      ))}
    </SidebarContainer>
  );
};

export default Sidebar;
