/*
 * This debounce utility function provides a way
 * to control the amount of times your function
 * can be called within a given time frame.
 * @param {function} func - function to be debounced
 * @param {int} milliseconds - amount of milli fvseconds before function can be called again
 */
// Ex: debounce(myFunction, 500);
export default function debounce(func, milliseconds) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      func();
    }, milliseconds);
  };
}
