import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const Banner = styled.img`
  background: url('${props => props.img}') no-repeat center / cover;
  height: 87vh;
  margin-top: 80px;
  width: 100%;

  @media (min-width: ${props => props.theme.media_breakpoint_tablet}) {
    margin-top: 100px;
  }

  @media (max-width: ${props => props.theme.media_breakpoint_tablet}) {
    background: url('${props => props.img}') no-repeat left / cover;
  }
`;

const HeroBanner = () => {
  const image = useStaticQuery(graphql`
    query fetchHeroImage {
      contentfulAsset(title: { in: "Banner" }) {
        fluid(maxWidth: 2000) {
          src
        }
      }
    }
  `);

  return <Banner img={image.contentfulAsset.fluid.src} />;
}; 

export default HeroBanner;
