import React from 'react';
import styled from 'styled-components';
import chevronUp from '../../images/scrollUpButton.svg';

const Icon = styled.div`
  align-items: center;
  background-color: #f7f7f7;
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: 2.5%;
  display: flex;
  height: 41px;
  justify-content: center;
  left: 86%;
  position: fixed;
  width: 41px;
  z-index: 2;

  > img { 
    height: 20px;
    width: 20px;
    z-index: 3;
  }

  @media (min-width: ${props => props.theme.media_breakpoint_desktop}) {
    display: none;
  }
`; 

const ScrollUpButton = ({ getParentRef }) => {
  const elem = getParentRef();

  const handleScroll = () => {
    // get ref from wrapper, on click scrollIntoView to wrapper
    elem.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Icon onClick={handleScroll}>
      <img src={chevronUp} alt="Scroll to top button" />
    </Icon>
  );
};

export default ScrollUpButton;
