import { createGlobalStyle } from 'styled-components';
import 'typeface-open-sans';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
  }

  p, label, input, textarea {
    color: ${props => props.theme.color_light_grey};
    font-size: 13px;
    line-height: 21.45px;
  }

  ol, ul {
    list-style: none;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.color_grey};

    :hover {
      color: #3b86b0;
    };
  }
`;

export default GlobalStyle;
