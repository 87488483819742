import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CompanyCard from '../components/CompanyCard/CompanyCard';
import Section from '../components/Section/Section';
import SectionContentContainer from '../components/Section/SectionContentContainer';
import SectionTitle from '../components/Section/SectionTitle';

const Clients = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query getClientsSection {
      contentfulClientsSectionTitle {
        title
      }
      allContentfulClientsSectionEntry(sort: { fields: name }) {
        nodes {
          name
          websiteUrl
          logo {
            fixed(width: 200) {
              src
            }
          }
        }
      }
    }
  `);

  const { title } = data.contentfulClientsSectionTitle;
  const { nodes } = data.allContentfulClientsSectionEntry;

  return (
    <Section ref={ref} backgroundColor="grey">
      <SectionTitle>
        <h2>{title}</h2>
      </SectionTitle>
      <SectionContentContainer>
        {nodes.map(({ logo, name }, key) => (
          <CompanyCard key={key} img={logo.fixed.src} name={name} />
        ))}
      </SectionContentContainer>
    </Section>
  );
});

export default Clients;
