// @flow
import React from 'react';
import HamburgerButton from './components/HamburgerButton';
import LinksContainer from './components/LinksContainer';
import LinkItem from './components/LinkItem';
import Logo from './components/Logo';
import NavContainer from './components/NavContainer';

const Navbar = ({
  getParentRef,
  handleSidebarToggle,
  handleScroll,
  sectionsRefs,
}) => {
  const wrapperRef = getParentRef();
  return (
    <>
      <NavContainer>
        <Logo>Anthony Electric</Logo>
        <HamburgerButton handleSidebarToggle={handleSidebarToggle} />
        <LinksContainer>
          <LinkItem
            handleClick={e => handleScroll(e, wrapperRef)}
            linkTitle="Home"
          />
          {Object.entries(sectionsRefs).map((section, key) => (
            <LinkItem
              handleClick={e => handleScroll(e, section[1])}
              key={key}
              linkTitle={section[0]}
            />
          ))}
        </LinksContainer>
      </NavContainer>
    </>
  );
};

export default Navbar;
