import React from 'react';
import styled from 'styled-components';

const StyledSectionEntry = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 10vh;
  width: 100%;

  .title {
    align-items: center;
    display: flex;
    justify-content: center;

    h4 {
      font-size: 1.313rem;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  .description {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100%;

    p {
      color: ${props => props.theme.color_light_grey};
      text-align: center;
    }
  }
`;

const Icon = styled.div`
  background: url(${props => props.icon}) no-repeat center center;
  height: 75px;
  margin: 0 0 1.5rem;
  width: 75px;
`;

const SectionEntry = ({ title, description, icon }) => {
  return (
    <StyledSectionEntry>
      {icon ? <Icon icon={icon} /> : null}
      <div>
        <div className="title">
          <h4>{title}</h4>
        </div>
        <div className="description">
          <p>{description}</p>
        </div>
      </div>
    </StyledSectionEntry>
  );
};

export default SectionEntry;
