import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ContactForm from '../components/ContactForm/ContactForm';
import Section from '../components/Section/Section';
import SectionTitle from '../components/Section/SectionTitle';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query getContactInfo {
      contentfulContactSectionTitle {
        title
      }
      contentfulContactSectionInfo {
        phoneNumber
        faxNumber
        mailingAddress
      }
    }
  `);

  const { title } = data.contentfulContactSectionTitle;
  const {
    phoneNumber,
    faxNumber,
    mailingAddress,
  } = data.contentfulContactSectionInfo;

  return (
    <Section divider>
      <SectionTitle>
        <h4>{title}</h4>
      </SectionTitle>
      <ContactForm
        phoneNumber={phoneNumber}
        faxNumber={faxNumber}
        mailingAddress={mailingAddress}
      />
    </Section>
  );
};

export default Contact;
