import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import Wrapper from '../components/Wrapper/Wrapper';

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <Wrapper />
  </ThemeProvider>
);

export default IndexPage;
