import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '../components/Section/Section';
import SectionContentContainer from '../components/Section/SectionContentContainer';
import SectionEntry from '../components/Section/SectionEntry';
import SectionTitle from '../components/Section/SectionTitle';

const About = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query getAboutSection {
      contentfulAboutSectionTitle {
        title
      }
      allContentfulAboutSectionEntry(sort: { fields: title }) {
        nodes {
          title
          description {
            description
          }
        }
      }
    }
  `);

  const { title } = data.contentfulAboutSectionTitle;
  const { nodes } = data.allContentfulAboutSectionEntry;

  return (
    <Section ref={ref}>
      <SectionTitle>
        <h3>{title}</h3>
      </SectionTitle>
      <SectionContentContainer>
        {nodes.map((entry, key) => (
          <SectionEntry
            key={key}
            title={entry.title}
            description={entry.description.description}
          />
        ))}
      </SectionContentContainer>
    </Section>
  );
});

export default About;
