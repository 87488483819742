import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '../components/Section/Section';
import SectionTitle from '../components/Section/SectionTitle';
import SectionContentContainer from '../components/Section/SectionContentContainer';

const LeaderCard = styled.div`
  text-align: center;
`;

const Leadership = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query getLeadershipEntries {
      allContentfulLeadershipSectionEntry(sort: { fields: name }) {
        nodes {
          name
          title
          phoneNumber
          email
          id
        }
      }
    }
  `);

  const { nodes } = data.allContentfulLeadershipSectionEntry;

  return (
    <Section ref={ref}>
      <SectionTitle>
        <h4>Leadership Team</h4>
      </SectionTitle>
      <SectionContentContainer>
        {nodes.map(({ email, name, phoneNumber, title }, key) => (
          <LeaderCard key={key}>
            <p>
              <strong>{name}</strong>
            </p>
            <p>
              <strong>{title}</strong>
            </p>
            <p>Cell: {phoneNumber}</p>
            <p>
              Email: <a href={`mailto:${email}`}>{email}</a>
            </p>
          </LeaderCard>
        ))}
      </SectionContentContainer>
    </Section>
  );
});

export default Leadership;
