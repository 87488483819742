import React from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';

const ContactFormContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 16px 0 64px;
  width: 90%;

  .info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    > div {
      margin: 0 0 20px;

      :last-child {
        margin: 0 0 40px;
      }
    }
  }

  .form {
    width: 100%;

    .form-row {
      margin: 0 0 20px;
    }

    input {
      height: 38px;
    }

    input,
    textarea {
      background-color: #f7f7f7;
      border: 1px solid #eee;
      border-radius: 3px;
      display: block;
      font-family: inherit;
      margin-top: 10px;
      padding: 10px;
      width: 100%;

      :focus {
        outline: none;
      }
    }

    .field-error {
      color: red;
      float: right;
      font-size: 14px;
    }

    button {
      background-color: #4a97c2;
      border: none;
      border-radius: 3px;
      color: #fff;
      font-size: 15px;
      padding: 8px 16px;
    }

    .success-message {
      border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
      color: green;
      margin-bottom: 5px;
      padding: 6px;
    }
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;

    .info {
      width: 50%;

      > :last-child {
        width: 75%;
      }
    }

    .form {
      width: 50%;
    }
  }
`;

const hubspotFormAPI =
  'https://api.hsforms.com/submissions/v3/integration/submit/7612896/8dfab068-142c-42e3-9341-c62305cdb234';

const ContactForm = ({ phoneNumber, faxNumber, mailingAddress }) => {
  const handleSubmit = (values, actions) => {
    const keys = Object.keys(values);

    const formData = {
      fields: keys.map(key => ({
        name: key,
        value: values[key],
      })),
    };

    fetch(hubspotFormAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.hasOwnProperty('errors')) {
          return alert('There was an error submitting the form');
        }

        actions.resetForm();
        actions.setStatus({ submitted: true });
        actions.setSubmitting(false);
      });
  };

  const validateEmail = value => {
    let errorMessage;
    if (!value) {
      errorMessage = 'Email Is Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      errorMessage = 'Invalid email address';
    }
    return errorMessage;
  };

  const requiredField = value => {
    if (!value) {
      return 'Is Required';
    }
    return;
  };

  return (
    <ContactFormContainer>
      <div className="info">
        <div>
          <p>
            <strong>Office:</strong>
          </p>
          <p>{phoneNumber}</p>
        </div>
        <div>
          <p>
            <strong>Fax:</strong>
          </p>
          <p>{faxNumber}</p>
        </div>
        <div>
          <p>
            <strong>Mailing Address:</strong>
          </p>
          <p>{mailingAddress}</p>
        </div>
      </div>
      <Formik
        initialValues={{ firstname: '', lastname: '', email: '', message: '' }}
        onSubmit={handleSubmit}
        initialStatus={{ submitted: false }}
      >
        {props => (
          <Form className="form">
            {props.status.submitted && !props.touched.firstname ? (
              <div className="success-message">
                Thank You for your submission.
              </div>
            ) : null}
            <div className="form-row">
              <label htmlFor="firstname">First Name: *</label>
              {props.errors.firstname && props.touched.firstname ? (
                <div className="field-error">Name {props.errors.firstname}</div>
              ) : null}
              <Field type="text" name="firstname" validate={requiredField} />
            </div>
            <div className="form-row">
              <label htmlFor="lastname">Last Name: *</label>
              {props.errors.lastname && props.touched.lastname ? (
                <div className="field-error">
                  Last Name {props.errors.lastname}
                </div>
              ) : null}
              <Field type="text" name="lastname" validate={requiredField} />
            </div>
            <div className="form-row">
              <label htmlFor="email">Email: *</label>
              {props.errors.email && props.touched.email ? (
                <div className="field-error">{props.errors.email}</div>
              ) : null}
              <Field type="email" name="email" validate={validateEmail} />
            </div>
            <div className="form-row">
              <label htmlFor="message">Message: *</label>
              <Field as="textarea" name="message" />
            </div>
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </ContactFormContainer>
  );
}; 

export default ContactForm;
